import type { StoreJourney } from '../types';

import type { AnyAction, Reducer } from 'redux';
import { actionTypes } from '../constants';


const initialState: StoreJourney[] = [];

const journeysReducer: Reducer<StoreJourney[], AnyAction> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case actionTypes.GET_STORE_JOURNEYS:
			state = action.payload as StoreJourney[];
			break;
	}
	return state;
};

export default journeysReducer;
