import type { FunctionComponent } from 'react';
import type { StoreJourney, Theme } from '../types';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/css';

import MenuButton from './MenuButton';
import Menu from './Menu';

import { logout } from '../actions';


const containerStyle = css`
	z-index: 999;
`;


interface Props {
	theme: Theme,
	journeys: StoreJourney[],
}

const MenuContainer: FunctionComponent<Props> = (props) => {
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);

	return(
		<div className={containerStyle}>
			<div>
				<MenuButton
					theme={props.theme}
					open={isOpen}
					onClick={!isOpen ? () => setIsOpen(true) : () => setIsOpen(false)}
					color='white'
				/>
			</div>
			<Menu
				onClick={() => setIsOpen(false)}
				logoutHandler={() => {
					setIsOpen(false);
					dispatch(
						logout()
					);
				}}
				open={isOpen}
			/>
		</div>
	);
};

export default MenuContainer;
