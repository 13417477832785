import type { RootState } from './store';

import React from 'react';
import { render } from 'react-dom';
import { connect, Provider } from 'react-redux';
import App from './container/App';

import store from './store';
import { loadData } from './actions';


console.info('API:', process.env.API_URL);


const authState = store.getState().auth;
if (authState.loggedIn && authState.access) {
	store.dispatch(
		loadData(authState.access)
	);
}

const ConnectedApp = connect(
	function mapStateToProps(state: RootState) {
		return state;
	}
)(App);

render(
	<React.StrictMode>
		<Provider store={store}>
			<ConnectedApp />
		</Provider>
	</React.StrictMode>,
	document.getElementById('app-root')
);
