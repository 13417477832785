import type { FunctionComponent } from 'react';
import type { StoreJourney, Theme } from '../types';

import { css } from '@emotion/css';

import { Link } from 'react-router-dom';
import MenuContainer from './MenuContainer';


const headerStyle = css`
	height: 10vh;
	padding: 10px;
	display: flex;
	flex: row wrap;
	justify-content: space-between;
	align-items: center;

	h1 {
		flex-grow: 1;
		padding-left: 1rem;
		font-size: 1.5rem;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		text-align: left;
	}
`;

const logoStyle = css`
	text-align: left;

	img {
		max-height: 10vh;
		padding: 0.5rem 0;
	}
`;


interface Props {
	theme: Theme,
	journeys: StoreJourney[],
}

const Header: FunctionComponent<Props> = (props: Props) => {
	return (
		<div className={headerStyle}>
			{!!props.theme.logo &&
				<Link className={logoStyle} to='/'>
					<img src={props.theme.logo}/>
				</Link>
			}
			<h1 style={{ color: props.theme.color }}>
				Beratungsboard
			</h1>
			<MenuContainer
				theme={props.theme}
				journeys={props.journeys}
			/>
		</div>
	);
};


export default Header;
