import type { FunctionComponent } from 'react';

import { css } from '@emotion/css';
import { useState } from 'react';

import ActiveFiltersContext from '../../context/active-filters-context';
import JourneyContext from '../../context/journey-context';
import { visualizationColors } from '../../constants';
import ColoredLens from './ColoredLens';


const ImgStyle = css`
	height: 100%;
	width: 100%;
	position: absolute;
	background-size: cover;
	background-position: center center;
	top: 0;
`;

const ImgStyle1 = css`
	z-index: 8;
`;

const ImgStyle2 = css`
	z-index: 9;
	mask-size: auto 120%;
	mask-repeat: no-repeat;
	mask-position: 50% 50%;
`;

const Frame = css`
	position: absolute;
	z-index: 20;
	width: 80% !important;
	left: 10%;
	top: -8%;
`;


interface Props {
	image1: string,
	image2: string,
	style: string,
}

const ImageCompareMouseFollow: FunctionComponent<Props> = (props) => {
	// const { height, width } = useWindowDimensions;
	const [coordinates, setCoordinates] = useState({ x: '0%', y: '0%' });
	const handleMouseMove = (event: React.MouseEvent) => {
		setCoordinates({
			x: `${event.clientX - innerWidth / 2}px`,
			y: `${event.clientY - innerHeight / 2}px`
		});
	};

	return (
		<JourneyContext.Consumer>
			{(/* journeys */) => {
				return <ActiveFiltersContext.Consumer>
					{(activeFilters) => {
						let c = '#ffffff';
						let opacity = '0';
						if (activeFilters.color) {
							const [/* label */, value] = activeFilters.color;
							c = visualizationColors[value] || c;
							opacity = '0.4';
						}
						const ColorOpacity = { opacity };

						const img = /* (journeys.pathname === 'kontakt')
							? 'circle_100.svg'
							: */ 'panton.svg';
						return <div
							className={css`
								${props.style}
								position: relative;
								overflow: hidden;
							`}
							onMouseMove={handleMouseMove}
						>
							<div
								className={css`
									${ImgStyle}
									${ImgStyle1}
									background-image: url(${props.image2});
								`}
							>
								{/* {journeys.pathname} */}
							</div>
							<div
								className={css`
									${ImgStyle}
									${ImgStyle2}
									mask: url(assets/img/${img});
									mask-size: auto 120%;
									mask-repeat: no-repeat;
									mask-position: ${coordinates.x} ${coordinates.y};
									background-image: url(${props.image1});
								`}
							/>
							<div
								className={css`
									${Frame};
									top: ${coordinates.y};
									left: ${coordinates.x};
								`}
							>
								<ColoredLens
									color={c}
									extraStyle={{
										...ColorOpacity,
										width: '100%',
									}}
								/>
								<img
									className={css`
										width: 100% !important;
									`}
									src="assets/img/panton_frame.svg"
								/>
							</div>
						</div>;
					}}
				</ActiveFiltersContext.Consumer>;
			}}
		</JourneyContext.Consumer>
	);
};

export default ImageCompareMouseFollow;
