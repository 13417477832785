import { FunctionComponent, useEffect, useState } from 'react';
import type { ActiveFilters, JourneyProduct } from '../types';
import * as R from 'ramda';

import { css, cx } from '@emotion/css';

import Result from './Result';
import PriceOverview from './PriceOverview';
import StoreContext from '../context/store-context';
import {
	colors,
	manufacturer
} from '../helper/DBtranslate';
import {
	FILTER_RESULTS_LIMIT_OPTIONS,
	DEFAULT_FILTER_RESULTS_LIMIT
} from '../constants';
import { formatPrice, makeBestellcode } from '../helper/utils';


const headerStyle = css`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: space-between;
	h1, h2 {
		margin-right: 1rem;
	}
`;

const warningStyle = css`
	margin: 0 auto;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: .1em;
	word-spacing: .3em;
	color: red;
	border: 2px solid red;
	border-radius: 5px;
	padding: 0.3rem 1rem;
`;

const filterValueStyle = css`
	margin-bottom: 2rem;
	display: flex;
	flex-direction: row;
	li {
		border: 1px solid black;
		border-radius: 1em;
		padding: .1em .5em;
		margin: 0 .5em 0 0;
	}
`;

const productListStyle = css`
	margin-bottom: 2rem;
	font-size: 1rem;
	li {
		display: flex;
		justify-content: space-between;
		padding: .5em 0;
		border-bottom: 1px dotted black;

		&:hover {
			background: rgba(0,0,0,.05);
		}
		&:first-of-type,
		&:last-of-type {
			border-bottom: 1px solid black;
		}
		&:first-of-type:hover {
			background: inherit;
		}
		div {
			text-align: left;
			width: 10%;
		}
		div:nth-of-type(2) {
			flex-grow: 2;
		}
		div:last-of-type {
			text-align: right;
			width: 7%;
		}
	}
`;

const wrapperStyle = css`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	border: 1px solid black;
	border-radius: 4px;
	margin: 1rem;
	& > div {
		margin-bottom: 2rem;
	}
	hr {
		width: 100%;
		border: none;
		border-bottom: 1px solid black;
	}
	h1 {
		font-size: 2rem;
	}
`;


function isProductInList(
	product: JourneyProduct,
	products: JourneyProduct[]
) {
	const listIds = products.map((item) => item.id);
	return listIds.includes(product.id);
}


const tooManyMessage = (numProducts: number, limit: number) => {
	if (numProducts > limit && limit != 0) {
		return `…es gibt über ${limit} (${numProducts}) Einträge, bitte schränken Sie Ihre Suche weiter ein.`;
	} else if (numProducts != 0) {
		return `die Suche ergibt ${numProducts} Einträge.`;
	} else {
		return null;
	}
};


const getManufacturer = (item: JourneyProduct) => {
	return manufacturer[item.product.producer];
};

const getProductName = (item: JourneyProduct) => {
	return item.product.name;
};

const getBestellcode = (item: JourneyProduct) => {
	return makeBestellcode(item.product);
};

const getIndex = (item: JourneyProduct) => {
	return item.product.index;
};

const getColor = (item: JourneyProduct) => {
	return colors[item.product.color];
};

const getCoating = (item: JourneyProduct) => {
	return item.product.coating;
};

const getPrice = (item: JourneyProduct) => {
	return item.calculated_price;
};

const sortFunctions: Record<string, (l: JourneyProduct[]) => JourneyProduct[]> = {
	'sortByManufacturer': R.sortBy(getManufacturer),
	'sortByProductName': R.sortBy(getProductName),
	'sortByBestellcode': R.sortBy(getBestellcode),
	'sortByIndex': R.sortBy(getIndex),
	'sortByColor': R.sortBy(getColor),
	'sortByCoating': R.sortBy(getCoating),
	'sortByPrice': R.sortBy(getPrice),
};


interface Props {
	activeFilters: ActiveFilters,
	products: JourneyProduct[],
}

const Selection: FunctionComponent<Props> = ({
	products,
	activeFilters
}) => {
	const activeFilterKeys = Object.keys(activeFilters);
	const [selectedGlas, setGlasSelection] = useState<JourneyProduct | null>(null);

	const [sortedProducts, setSortedProducts] = useState(products);
	const [sortSettings, setSortSettings] = useState<[string, number]>(
		['sortByManufacturer', -1]
	);
	const sortProducts = (prods: JourneyProduct[]) => {
		const [sortFnName, sortDirection] = sortSettings;
		let sorted = sortFunctions[sortFnName](prods);
		if (sortDirection > 0) {
			sorted = R.reverse(sorted);
		}
		setSortedProducts(sorted);
	};
	useEffect(
		() => sortProducts(products),
		[products, sortSettings]
	);

	const [resultsLimit, setResultsLimit] = useState(DEFAULT_FILTER_RESULTS_LIMIT);
	const showResults = (resultsLimit === 0)
		? true
		: (products.length < resultsLimit && products.length !== 0);

	return (
		<StoreContext.Consumer>
			{(store) => {
				const productListExtraStyles = css`
					li:hover:not(:first-child) {
						color: ${store.color};
						cursor: pointer;
					}
					&:first-child:hover {
						color: inherit;
					};
				`;

				const columns = [
					{ label: 'Hersteller', sortFn: 'sortByManufacturer' },
					{ label: 'Name', sortFn: 'sortByProductName' },
					{ label: 'Bestellcode', sortFn: 'sortByBestellcode' },
					{ label: 'Index', sortFn: 'sortByIndex' },
					{ label: 'Farbe', sortFn: 'sortByColor' },
					{ label: 'Beschichtung', sortFn: 'sortByCoating' },
					{ label: 'Preis (€)', sortFn: 'sortByPrice' },
				];
				const tableHeader = <li>
					{columns.map((item) => {
						return <div
							key={item.label}
							onClick={() => {
								setSortSettings([
									item.sortFn,
									(item.sortFn === sortSettings[0])
										? -1 * sortSettings[1]
										: -1
								]);
							}}
							style={{ cursor: 'pointer' }}
						>
							{item.label}
							<span>
								{(sortSettings[0] === item.sortFn)
									? (sortSettings[1] > 0) ? ' ↑' : ' ↓'
									: ''
								}
							</span>
						</div>;
					})}
				</li>;

				return <div className={wrapperStyle}>
					{(products.length > 0)
						? <PriceOverview glasList={products} />
						: <h2 className={warningStyle}>
							Achtung: Die Auswahl ergibt keine Treffer!
						</h2>
					}
					{(products.length > 0)
						? <div className={headerStyle}>
							<div>
								<h1>Gläserliste</h1>
								<p>{tooManyMessage(products.length, resultsLimit)}</p>
							</div>
							<div>
								<span>Auf </span>
								<select
									value={resultsLimit}
									onChange={(event) => {
										setResultsLimit(
											parseInt(event.target.value)
										);
									}}
								>
									{FILTER_RESULTS_LIMIT_OPTIONS.map((n) => {
										return <option key={n} value={n}>
											{(n === 0) ? 'alle' : n}
										</option>;
									})}
								</select>
								<span> Resultate begrenzen</span>
							</div>
						</div>
						: null
					}
					{(activeFilterKeys.length > 0)
						? <ul className={filterValueStyle}>
							{activeFilterKeys.map((key) => {
								const [label, val] = activeFilters[key];
								return <li key={key}>{label}: {val}</li>;
							})}
						</ul>
						: null
					}
					{(showResults) ?
						<ul className={cx(productListStyle, productListExtraStyles)}>
							{tableHeader}
							{sortedProducts.map((item) => {
								return <li
									key={item.id}
									onClick={() => setGlasSelection(item)}
								>
									<div>{getManufacturer(item)}</div>
									<div>{getProductName(item)}</div>
									<div>{getBestellcode(item)}</div>
									<div>{getIndex(item)}</div>
									<div>{getColor(item)}</div>
									<div>{getCoating(item)}</div>
									<div>{formatPrice(getPrice(item))}</div>
								</li>;
							})}
						</ul>
						: null
					}
					{selectedGlas && <Result
						selection={selectedGlas}
						inactive={!isProductInList(selectedGlas, products)}
					/>}
				</div>;
			}}
		</StoreContext.Consumer>
	);
};

export default Selection;
