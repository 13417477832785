import type { FunctionComponent } from 'react';

import { css } from '@emotion/css';


const BaseStyle = css`
	position: absolute;
	left: 10px;
	z-index: 10;
	rotation: 90deg; /* TODO: remove or fix this */
	color: white;
	bottom: 10px;

	i {
		font-size: 5rem;
	}

	:hover {
		bottom: 5px;
	}

	cursor: pointer;
	transition: bottom 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
`;


interface Props {
	onButtonClick: () => void,
	down: boolean
}

const ScrollToArrow: FunctionComponent<Props> = ({ down, onButtonClick }) => {
	const animStyle = {
		transform: down ? 'rotate(-180deg)' : 'rotate(0deg)',
	};
	return(
		<div
			className={css`
				${BaseStyle}
				color: white;
			`}
			style={animStyle}
			onClick={onButtonClick}
		>
			<i className="material-icons">arrow_downward</i>
		</div>
	);
};

export default ScrollToArrow;
