// TODO: DRY
import type { FunctionComponent } from 'react';

interface Props {
	goToNextSlide: () => void,
	style: React.CSSProperties,
}

const RightArrow: FunctionComponent<Props> = ({
	goToNextSlide,
	style
}) => {
	return (
		<div
			className="backArrow"
			style={style}
			onClick={goToNextSlide}
		>
			<i className="material-icons">arrow_forward</i>
		</div>
	);
};

export default RightArrow;
