import type { FunctionComponent } from 'react';
import type { AuthState, StoreJourney, Theme } from '../types';

import { Link } from 'react-router-dom';
import chroma from 'chroma-js';
import { css } from '@emotion/css';

import LoginForm from './LoginForm';
import { FALLBACK_COLOR } from '../constants';


const gridStyle = css`
	display: grid;
	grid-template-rows: auto;
	grid-auto-flow: row;
	align-items: center;
	padding: 0.5rem;
	height: 80vh;
`;

const gridSize1Style = css`
	grid-template-columns: 1fr 1fr;
`;

const gridSize2Style = css`
	grid-template-columns: 1fr 1fr 1fr;
`;

const tileStyle = css`
	margin: 0 auto;
	display: flex;
	margin: 0.5rem;
	align-self: stretch;
	flex-flow: column;
	overflow: hidden;
	justify-content:space-around;

	h2 {
		transition: all 0.2s ease-in-out;
		opacity: 0.2;
		text-decoration: none;
	}

	&:hover {
		h2 {
			text-decoration: strike-through !important;
			opacity: 1;
		}
	}
`;

const imgContainerStyle = css`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 0;
`;

const imgStyle = css`
	transition: all 0.2s ease-in-out;
	width: 90%;
	height: 90%;
	:hover {
		transform: scaleX(1.2) scaleY(1.2);
	}

`;

const titleStyle = css`
	padding: 1rem;
	font-size: 1.5rem;
	text-align: center;
	width: 100%;
`;

// TODO: DRY -- I've seen this elsewhere
const greetingStyle = css`
	display: flex;
	border-radius: 4px;
	margin: 0 1rem;
	color: white;
	width: calc(100% - 2rem);
	height: 10vh;
	align-items: center;
	h1 {
		font-weight: 300;
		width: 100%;
		text-align: center;
		font-size: 1.5rem;
		line-height: 1.4em;
	}
`;


interface Props {
	theme: Theme,
	journeys: StoreJourney[],
	auth: AuthState,
}

const Home: FunctionComponent<Props> = ({ theme, journeys, auth }) => {
	if (!auth.loggedIn) {
		return <LoginForm />;
	}

	const c = chroma(theme.color || FALLBACK_COLOR);

	return (
		<div>
			<div
				className={css`
					${greetingStyle}
					background: linear-gradient(90deg, ${c.brighten(1).hex()} 0%, ${theme.color} 100%);
				`}
			>
				<h1>{theme.greeting}</h1>
			</div>
			<div
				className={css`
					${gridStyle}
					${journeys.length < 5 ? gridSize1Style : gridSize2Style}
				`}
			>
				{journeys.map((item) => {
					return (
						<Link
							className={css`
								${tileStyle}
								color: ${theme.color};
							`}
							to={`/${item.journey.pathname}`}
							key={item.journey.pathname}
						>
							<link rel="preload" href={item.journey.indexImage} as="image" />
							<div className={imgContainerStyle}>
								<div
									// src='assets/img/Kinderbrillen.svg'
									className={css`
										${imgStyle}
										background-color: ${c.brighten(.3).hex()};
										&:hover { background-color: ${c.darken(.3).hex()};}
										mask: url(assets/img/${item.journey.pathname}.svg) no-repeat center / contain;
										-webkit-mask: url(assets/img/${item.journey.pathname}.svg) no-repeat center / contain;
									`}
								></div>
							</div>
							<h2
								className={css`
									${titleStyle};
									title {
										color: ${theme.color};
									}
								`}
							>
								{item.journey.label}
							</h2>
						</Link>
					);
				})}
			</div>
		</div>
	);
};

export default Home;
