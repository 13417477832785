import type { FunctionComponent } from 'react';

import { css } from '@emotion/css';
import { useState } from 'react';


const pageStyle = css`
	display: flex;
	flex-direction: column;
	align-content: space-between;
	width: 100%;
	padding: 1rem;

	h1 {
		font-size: 2rem;
		margin-bottom: 2rem;
	}
`;

const bgStyle = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const overlayStyle = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: opacity 1s ease;
`;

const illustrationStyle = css`
	width: 100%;
	height: 40vw;
	position: relative;
`;

const buttonArrayStyle = css`
	display: flex;
	justify-content: space-around;
	flex-direction: row;

	div {
		background: rgba(255, 255, 255, 30%);
		font-size: 2rem;
		font-weight: 400;
		border-radius: 4px;
		padding: 1rem 8rem;
		cursor: pointer;
		transition: background 1s ease;
	}
`;

const activeStyle = css`
	background: rgba(255, 255, 255, 100%) !important;
`;


interface ButtonProps {
	className: string,
	onButtonClick: () => void,
	label: string,
}

const Button: FunctionComponent<ButtonProps> = (props) => {
	// const active = false;
	return <div
		className={props.className}
		onClick={props.onButtonClick}>
		{props.label}
	</div>;
};


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const DistanceSlide: FunctionComponent<Props> = () => {
	const [distance, setDistance] = useState('');

	return(
		<div className={pageStyle}>
			<h1>Die Schärfeoptimierung verschiedener Officegläser</h1>
			<div className={illustrationStyle}>
				<img
					className={bgStyle}
					src="assets/img/DistanceIllu.svg"
				/>
				<img
					className={css`
						${overlayStyle}
						opacity: ${distance === 'book' ? 0.7 : 0 };
					`}
					src="assets/img/book.svg"
				/>
				<img
					className={css`
						${overlayStyle}
						opacity: ${distance === 'near' ? 0.7 : 0 };
					`}
					src="assets/img/near.svg"
				/>
				<img
					className={css`
						${overlayStyle}
						opacity: ${distance === 'room' ? 0.7 : 0 };
					`}
					src="assets/img/room.svg"
				/>
			</div>
			<div className={buttonArrayStyle}>
				<Button
					label='Book'
					className={distance === 'book' ? activeStyle : ''}
					// value='book'
					onButtonClick={() => { setDistance('book'); }}
				/>
				<Button
					label='Near'
					className={distance === 'near' ? activeStyle : ''}
					// value='near'
					onButtonClick={() => { setDistance('near'); }}
				/>
				<Button
					label='Room'
					className={distance === 'room' ? activeStyle : ''}
					// value='room'
					onButtonClick={() => { setDistance('room'); }}
				/>
			</div>
		</div>
	);
};

export default DistanceSlide;
