import type { ActiveFilters, JourneyProduct, Product } from '../types';

import * as R from 'ramda';

import { testFunction } from './filters';


export const formatPrice = (price: number) => {
	return `${price} €`;
};


export const formatPriceRange = (minPrice: number, maxPrice: number) => {
	return `${formatPrice(minPrice)} – ${formatPrice(maxPrice)}`;
};


export const makeBestellcode = (product: Product) => {
	return product.nameCode + product.coatingCode;
};


export const parseFilterOptionValues = (values: string) => {
	return values.split(/[\r\n]+/);
};


export function filterProducts(
	products: JourneyProduct[],
	activeFilters: ActiveFilters
) {
	return products.filter((p) => {
		// example: ['thickness', 'quality', 'sunProtection', 'color']
		const keys = Object.keys(activeFilters);

		const results = keys.map((key) => {
			const filterValue = activeFilters[key][1];
			return testFunction[key](p, filterValue); // [true, false, ...]
		});

		// a product makes it throught the filter ONLY of all the
		// filter functions return true
		const trues = results.filter((bool) => bool);
		return (trues.length === keys.length);
	});
}


export function determineDisabledFilters(
	activeFilters: ActiveFilters,
	disabledFilters: string[]
): string[] {
	let result = [...disabledFilters];

	if (activeFilters.thickness?.[1] === '1.74' &&
		!disabledFilters.includes('sunProtection')
		// TODO: shouldn't this check for `color` as well?
	) {
		result = [...disabledFilters, 'sunProtection', 'color'];
	}
	else if (activeFilters.thickness?.[1] !== '1.74' &&
		disabledFilters.includes('sunProtection')
		// TODO: shouldn't this check for `color` as well?
	) {
		result = R.without(['sunProtection', 'color'], disabledFilters);
	}

	// no colors for 'klar'...
	else if (activeFilters.sunProtection?.[1] === 'klar' &&
		!disabledFilters.includes('color')
	) {
		result = [...disabledFilters, 'color'];
	} // ...reverse
	else if (activeFilters.sunProtection?.[1] !== 'klar' &&
		disabledFilters.includes('color')
	) {
		result = R.without(['color'], disabledFilters);
	}

	return R.uniq(result);
}
