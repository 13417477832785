import type { FunctionComponent } from 'react';

interface Props {
	goToPrevSlide: () => void,
	style: React.CSSProperties,
}

const LeftArrow: FunctionComponent<Props> = ({
	goToPrevSlide,
	style
}) => {
	return (
		<div
			className="backArrow"
			style={style}
			onClick={goToPrevSlide}
		>
			<i className="material-icons">arrow_back</i>
		</div>
	);
};

export default LeftArrow;
