import type { FunctionComponent } from 'react';

import { css } from '@emotion/css';
import marked from 'marked';

import Slide from './Slide';


const containerStyle = css`
	h1 {
		margin: 1rem;
		font-size: 2rem;
		line-height: 1.4;
	}

	h2 {
		margin-bottom: 1rem;
		font-size: 1.5rem;
		line-height: 1.4;
	}

	p,
	ol,
	ul {
		line-height: 1.2;
		font-weight: 300;
	}

	img {
		max-width: 100%;
	}
`;

const slideStyle = css`
	margin: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	max-width: calc(100% - 2rem);
	max-height: 75vh;
	overflow: scroll;

	pre {
		white-space: -moz-pre-wrap !important;
		white-space: -pre-wrap;
		white-space: -o-pre-wrap;
		white-space: pre-wrap;
		word-wrap: break-word;
	}

	img {
		flex-grow: 2;
	}

	ul {
		list-style: disc inside none;

		ul {
			margin-left: 1rem;
			list-style: square inside none;
		}
	}

	ol {
		list-style: decimal inside none;
	}

	p {
		max-width: 45em;
	}

	table {
		width: 100%;

		tr {
			border-top: 1px solid black;
			border-bottom: 1px solid black;

			&:nth-of-type(odd) {
			}
		}

		tbody tr {
			&:hover {
				background: rgba(255, 255, 255, 50%);
			}
		}

		th,
		td {
			padding: 0.5rem 1rem;
		}

		td:hover {
			background: rgba(255, 255, 255, 100%);
		}

		thead {
			text-align: left;

			th {
				font-weight: 400;
				background: rgba(255, 255, 255, 50%);
			}
		}
	}
`;

const big = css`
	margin: 4rem;

	h1,
	h2,
	p,
	table,
	ol,
	ul {
		font-size: 1rem;
		font-weight: 300;
	}
	p { line-height: 1.4; }

	ol,
	ul {
		line-height: 1;
	}
`;

const small = css`
	h1,
	h2,
	p,
	table,
	ol,
	ul {
		line-height: 1;
	}

	ol,
	ul {
		line-height: 1;
	}

`;

const splitStyle = css`
	pre {
		max-width: 66%;
		min-width: 33%;
		padding-right: 1rem;
	}

	img {
		flex-grow: 2;
		object-fit: contain;
	}
`;


const fullSize = css`
	pre {
		max-width: 100%;
		min-width: 33%;
	}
`;


interface Props {
	title: string,
	text?: string,
	image?: string
}

const BackendSlide: FunctionComponent<Props> = (props) => {
	const html = marked(props.text || '');
	const content = (
		<div className={containerStyle}>
			<h1>{props.title}</h1>
			<div
				id={props.title.toString()}
				className={css`
					${slideStyle}
					${props.text && props.text.length < 1000 ? big : small}
					${props.image ? splitStyle : fullSize}
				`}
			>
				{html && <pre dangerouslySetInnerHTML={{ __html: html }} />}
				{props.image && <img alt={props.title} src={props.image} />}
			</div>
		</div>
	);
	return <Slide content={content} />;
};

export default BackendSlide;
