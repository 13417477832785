import type { FunctionComponent, CSSProperties } from 'react';
import type { Theme } from '../types';


interface Props {
	theme: Theme,
	onClick: () => void,
	open: boolean,
	color: string
}

const MenuButton: FunctionComponent<Props> = (props) => {
	const styles: Record<string, CSSProperties> = {
		container: {
			zIndex: '999',
			height: '50px',
			width: '50px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			cursor: 'pointer',
			padding: '4px',
		},
		line: {
			height: '4px',
			width: '35px',
			background: props.theme.color,
			transition: 'all 0.2s ease',
		},
		lineTop: {
			transform: props.open ? 'translateX(2px) rotate(45deg)' : 'none',
			transformOrigin: 'top left',
			marginBottom: '8px',
		},
		lineMiddle: {
			opacity: props.open ? 0 : 1,
			transform: props.open ? 'translateX(-16px)' : 'none',
		},
		lineBottom: {
			transform: props.open ? 'translateX(0px) rotate(-45deg)' : 'none',
			transformOrigin: 'top left',
			marginTop: '8px',
		},
	};

	return(
		<div
			style={styles.container}
			onClick={props.onClick}
		>
			<div style={{ ...styles.line, ...styles.lineTop }}/>
			<div style={{ ...styles.line, ...styles.lineMiddle }}/>
			<div style={{ ...styles.line, ...styles.lineBottom }}/>
		</div>
	);
};

export default MenuButton;
