import type { FunctionComponent } from 'react';
import type { JourneyType, SlideShow } from '../types';

import { useState, useEffect } from 'react';
import { css } from '@emotion/css';

import Slides from './Slides';
import Slide from './Slide';
import BackendSlide from './BackendSlide';
import InfoButton from './InfoButton';


const sectionStyle = css`
	transition: height 0.4s ease-in-out;
	border-bottom: 1px solid black;
	margin: 0 1rem;
	overflow: hidden;
`;

const rowStyle = css`
	font-size: 1.8rem;
	display: flex;
	align-content: space-between;
	padding: 10px 0;
	display: flex;

	.spacer {
		flex-grow: 10;
	}

	h2 {
		padding: 4px 0 0;
	}
`;


const infoSlideContents: Record<string, JSX.Element[]> = {
	sonnenbrillen: [],
	gleitsicht: [], //null,
	// TODO: add rest..
};


interface Props {
	journey: JourneyType,
	slideShow: SlideShow,
}

const InfoSection: FunctionComponent<Props> = ({
	slideShow = { slides: [] },
	journey
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const id = journey.pathname;

	useEffect(() => {
		if (isOpen) {
			const elem = document.getElementById(`#section_${id}`);
			if (elem) {
				elem.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}), [isOpen];

	let slides: JSX.Element[] = [];
	if (infoSlideContents[id] && infoSlideContents[id].length) {
		slides = infoSlideContents[id].map((content, i) => {
			return <Slide
				key={`info-slide-${i}`}
				content={content}
			/>;
		});
	}
	const backendSlides = slideShow.slides.map((slide, i) => {
		return <BackendSlide
			key={i}
			title={slide.title}
			text={slide.text || undefined}
			image={slide.image || undefined}
		/>;
	});
	slides = [
		...slides,
		...backendSlides
	];
	const showSlideShow = isOpen && slides.length > 0;

	return (
		<div className={sectionStyle} id={`#section_${id}`}>
			<div className={rowStyle}>
				<h2>Info für {journey.label}</h2>
				<div className="spacer"></div>
				<InfoButton
					open={isOpen}
					onButtonClick={() => setIsOpen(!isOpen)}
				/>
			</div>
			{(showSlideShow)
				? <Slides key="info" slides={slides} />
				: null
			}
		</div>
	);
};

export default InfoSection;
