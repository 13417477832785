import type { FunctionComponent } from 'react';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/css';

import { loginRequest, setAuthTokens, loadData } from '../actions';


const style = css`
	padding-left: calc(10px + 1rem);

	input,
	button {
		font-family: "kaski";
		margin-bottom: 0.5rem;
	}

	button {
		background: rgba(0, 0, 0, 50%);
		border: none;
		border-radius: 4px;
		color: white;
		padding: 0.2rem 2rem;
		transition: background 0.1s;

		&:hover {
			background: rgba(0, 0, 0, 70%);
		}
	}

`;

const initialState = {
	username: '',
	password: '',
};


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const LoginForm: FunctionComponent<Props> = () => {
	const dispatch = useDispatch();
	const [state, setState] = useState(initialState);

	const handleSubmit = () => {
		dispatch(
			loginRequest(state.username, state.password, (err, data) => {
				if (err || !data?.access || !data?.refresh) {
					console.error(err);
					console.log(data);
					return alert('Anmeldung fehlgeschlagen');
				}
				setState(initialState);
				dispatch(
					setAuthTokens(data.access, data.refresh)
				);
				dispatch(
					loadData(data.access)
				);
			})
		);
	};

	return <form className={style} onSubmit={handleSubmit}>
		<div>
			<input
				value={state.username}
				type="text"
				placeholder="Benutzer"
				onChange={(event) => {
					setState({ ...state, username: event.target.value });
				}}
			/>
		</div>
		<div>
			<input
				value={state.password}
				type="password"
				placeholder="Passwort"
				onChange={(event) => {
					setState({ ...state, password: event.target.value });
				}}
			/>
		</div>
		<button type="submit">Anmelden</button>
	</form>;
};

export default LoginForm;
