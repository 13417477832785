import type { FunctionComponent } from 'react';
import type { JourneyProduct } from '../types';

import { useState } from 'react';
import chroma from 'chroma-js';
import marked from 'marked';
import { css } from '@emotion/css';

import StoreContext from '../context/store-context';
import {
	distances,
	sunProtections,
	colors,
	manufacturer
} from '../helper/DBtranslate';
import { makeBestellcode } from '../helper/utils';


const containerStyle = css`
	font-size: 1rem;
	display: flex;
	flex-direction: column;

	h1 {
		padding: 10px 0;
		font-size: 2rem;
	}
`;

const selectionStyle = css`
	font-size: 2rem;
	padding-top: 1rem;
	display: flex;
	flex: row wrap;
	justify-content: space-between;
	width: 100%;

	div {
		padding: 10px 0;
	}

	div:first-of-type {
		text-align: left;
	}
`;

const buttonStyle = css`
	color: white;
	font-size: 2rem;
	border-radius: 4px;
	text-align: center;
	padding: 0.6em 4em;
	margin: 3rem auto 1rem;
	width: 66%;
	cursor: pointer;
`;

const selectedProductStyle = css`
	display: flex;
	border-top: 1px solid black;
	border-bottom: 1px solid black;
	margin-bottom: 2rem;
	justify-content: space-between;

	p {
		font-size: 1.5rem;
		font-weight: 400;
	}
`;

const tableWrapperStyle = css`
	margin-top: 3rem;

	table {
		width: 100%;

		th,
		td {
			padding: 0.5em 1em;
			border-bottom: 1px solid black;
		}
	}
`;


interface Props {
	selection: JourneyProduct,
	inactive: boolean
}

const Result: FunctionComponent<Props> = (props) => {
	const result = props.selection;
	const p = result.product;
	const distance = distances[p.distance];
	const protection = sunProtections[p.protection];
	const producerId = p.producer;
	const buttonLabel = 'Bestell-Code kopieren';
	const [successMessage, setSuccessMessage] = useState<string | null>(null);

	function copyToClipboard() {
		const elem = document.querySelector('#bestellcode') as HTMLInputElement;
		if (!elem || !navigator.clipboard) {
			alert('Fehler beim Kopieren.');
			return;
		}
		const text = elem.value || '';
		navigator.clipboard.writeText(text)
			.then(() => {
				setSuccessMessage('Code kopiert!');
				setTimeout(
					() => setSuccessMessage(null),
					2000
				);
			});
	}

	return (
		<StoreContext.Consumer>
			{(store) => {
				return <div className={containerStyle}>
					<div>
						<div className={selectionStyle}>
							<h1>{`Auswahl: ${props.inactive ? 'nicht in Liste' : ''}`}</h1>
						</div>
						<div>
							<div className={selectedProductStyle}>
								<p>{p.name}</p>
								<p>{result.calculated_price}{' €'}</p>
							</div>
							{`Hersteller: ${manufacturer[p.producer]} `}<br />
							{`Glasindex: ${p.index} `}<br />
							{`Beschichtung: ${p.coating} `}<br />
							{distance ? `Entfernung: ${distance} ` : null}
							{distance ? <br /> : null}
							{protection ? `Sonnenschutz: ${protection} ` : null}<br />
							{(colors[p.color] != null) ? `Farbe: ${colors[p.color]} ` : null}
						</div>

						<div
							dangerouslySetInnerHTML={{
								__html: marked(store.extras_markdown_table[producerId] || '')
							}}
							className={tableWrapperStyle}
						/>

						<input
							style={{ fontSize: 0, height: 0, width: 0, border: 'none' }}
							id="bestellcode"
							type="text"
							value={makeBestellcode(p)}
						/>
						<div
							className={css`
								${buttonStyle}
								background: ${store.color};
								&:hover {
									background: ${chroma(store.color).darken(0.2).hex()};
								}
							`}
							onClick={copyToClipboard}
						>
							{successMessage
								? successMessage
								: buttonLabel + ': ' + makeBestellcode(p)
							}
						</div>
					</div>
				</div>;
			}}
		</StoreContext.Consumer>
	);
};

export default Result;
