import type { FunctionComponent } from 'react';
import type { JourneyProduct } from '../types';

import { css } from '@emotion/css';

import ProductsContext from '../context/products-context';
import { formatPriceRange } from '../helper/utils';


const TableContainer = css`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	p,
	h1 {
		margin: 1rem;
	}

	h1 {
		font-size: 2rem;
	}
`;

const TableStyle = css`
	margin: 5rem;
	width: 80%;

	thead {
		text-align: right;
		padding-bottom: 0.5rem;
		border-bottom: 1px solid black;
	}

	tbody {
		text-align: right;

		td:first-of-type {
			background-color: lightgrey;
		}
	}

	thead th,
	tbody td {
		padding: 1rem 0.5rem;
	}
`;


const getPriceRange = (
	products: JourneyProduct[],
	thickness: number,
	quality: number
) => {
	const prices = [];
	for (let i = 0; i < products.length; i++) {
		const p = products[i];
		if (
			p.product.index === thickness &&
			p.product.quality === quality
		) {
			prices.push(p.calculated_price);
		}
	}

	const minPrice = Math.min(...prices);
	const maxPrice = Math.max(...prices);
	return (prices.length > 0)
		? formatPriceRange(minPrice, maxPrice)
		: '———';
};


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const PriceTable: FunctionComponent<Props> = () => {
	return (
		<ProductsContext.Consumer>
			{(products) =>
				<div className={`${TableContainer} slide`}>
					<h1>Preisübersicht</h1>
					<p>Alle Preise beziehen sich auf das Glaspaar</p>
					<table className={TableStyle}>
						<thead>
							<tr>
								<th>Index</th>
								<th>Standard</th>
								<th>Komfort</th>
								<th>Premium</th>
								<th>Individual</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1.74</td>
								<td>{getPriceRange(products, 1.74, 0)}</td>
								<td>{getPriceRange(products, 1.74, 1)}</td>
								<td>{getPriceRange(products, 1.74, 2)}</td>
								<td>{getPriceRange(products, 1.74, 3)}</td>
							</tr>
							<tr>
								<td>1.67</td>
								<td>{getPriceRange(products, 1.67, 0)}</td>
								<td>{getPriceRange(products, 1.67, 1)}</td>
								<td>{getPriceRange(products, 1.67, 2)}</td>
								<td>{getPriceRange(products, 1.67, 3)}</td>
							</tr>
							<tr>
								<td>1.6</td>
								<td>{getPriceRange(products, 1.6, 0)}</td>
								<td>{getPriceRange(products, 1.6, 1)}</td>
								<td>{getPriceRange(products, 1.6, 2)}</td>
								<td>{getPriceRange(products, 1.6, 3)}</td>
							</tr>
							<tr>
								<td>1.5</td>
								<td>{getPriceRange(products, 1.5, 0)}</td>
								<td>{getPriceRange(products, 1.5, 1)}</td>
								<td>{getPriceRange(products, 1.5, 2)}</td>
								<td>{getPriceRange(products, 1.5, 3)}</td>
							</tr>
						</tbody>
					</table>
				</div>
			}
		</ProductsContext.Consumer>
	);
};

export default PriceTable;
