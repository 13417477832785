import type { AnyAction, Reducer } from 'redux';
import type { Store } from '../types';

import { actionTypes, FALLBACK_COLOR } from '../constants';


export const initialState: Store = {
	color: FALLBACK_COLOR,
	greeting: '',
	logo: null,
	id: -1,
	extras_markdown_table: {},
};


const themeReducer: Reducer<Store, AnyAction> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case actionTypes.SET_STORE: {
			const theme = action.payload as Store;
			theme.color = theme.color || FALLBACK_COLOR;
			state = theme;
			break;
		}
	}
	return state;
};

export default themeReducer;
