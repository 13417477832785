import type { FunctionComponent } from 'react';

import { css } from '@emotion/css';
import { arrowStyle } from '../../styles';


const pageStyle = css`
	display: flex;
	flex-direction: column;
	align-content: space-between;
	width: 100%;
	padding: 1rem;

	h1 {
		font-size: 2rem;
		margin-bottom: 2rem;
	}
`;

const containerStyle = css`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
`;

// const itemStyle = css`
// 	text-align: center;
// 	text-align: -webkit-center;
// 	padding: 1rem;
// 	transition: background-color 0.4s;

// 	&:hover {
// 		background-color: white;
// 	}

// 	div,
// 	img,
// 	h2 {
// 		margin: 1rem 0;
// 	}

// 	img {
// 		height: 300px;
// 	}
// `;

const qualitiesStyle = css`
	margin-bottom: 3rem;

	ul {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
`;

// const options = [
// 	{
// 		imgSrc: 'assets/img/filterContent/quality_compare_02_01.png',
// 		label: 'niedrige Qualität',
// 		text: '',
// 	},
// 	{
// 		imgSrc: 'assets/img/filterContent/quality_compare_02_02.png',
// 		label: 'mittlere Qualität',
// 		text: '',
// 	},
// 	{
// 		imgSrc: 'assets/img/filterContent/quality_compare_02_03.png',
// 		label: 'hohe Qualität',
// 		text: '',
// 	},
// ];


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const QualityCompare_02: FunctionComponent<Props> = () => {
	return(
		<div className={pageStyle}>
			<h1>Verzerrungen unterschiedlicher Qualitäten</h1>
			<div className={qualitiesStyle}>
				<ul>
					<li>Standard</li>
					<li>Komfort</li>
					<li>Premium</li>
					<li>Individual</li>
				</ul>
				<div className={arrowStyle}>
					<span className="arrowbody" />
					<span className="arrowhead" />
				</div>
			</div>
			<div className={containerStyle}>
				{/* options.map((item, i) => (
					<div key={i} className={itemStyle}>
						<img src={item.imgSrc} />
						<h2>{item.label}</h2>
						<p>{item.text}</p>
					</div>
				)) */}
				<img src="assets/img/filterContent/zeiss-gleitsicht-vergleich.jpg" />
			</div>
		</div>
	);
};

export default QualityCompare_02;
