import type { FunctionComponent } from 'react';

import { css } from '@emotion/css';
import Radio from '@material-ui/core/Radio';


const rButtonStyle = css`
	font-family: inherit !important;

	i {
		color: black;
		font-size: 1.5rem;
	}
`;

// TODO: DRY -- extend normal style
const rButtonStyleDisabled = css`
	font-family: inherit !important;

	i {
		color: rgba(0, 0, 0, 50%);
		font-size: 1.5rem;
	}
`;


interface Props {
	checked?: boolean,
	disabled?: boolean,
}

const StyledRadio: FunctionComponent<Props> = (props) => {
	return (
		<Radio
			className={!props.disabled ? rButtonStyle : rButtonStyleDisabled}
			disableRipple
			checkedIcon={<i className="material-icons">radio_button_checked</i>}
			icon={<i className="material-icons">radio_button_unchecked</i>}
			{...props}
		/>
	);
};

export default StyledRadio;
