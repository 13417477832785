import type { JourneyProduct, Store, StoreJourney } from './types';


const authHeader = (token: string) => {
	return { Authorization: `Bearer ${token}` };
};


export const loadStores = (token: string): Promise<Store[]> => {
	return fetch(
		`${process.env.API_URL}/stores`,
		{
			headers: {
				...authHeader(token),
			},
		}
	)
		.then((res) => res.json());
};

// export const loadStore = (token, storeId) => {
//   return fetch(
//     `${process.env.API_URL}/stores/${storeId}`,
//     {
//       headers: {
//         ...authHeader(token),
//       },
//     }
//   )
//     .then((res) => res.json());
// };

export const loadJourneys = (
	token: string,
	storeId: number
): Promise<StoreJourney[]> => {
	return fetch(
		`${process.env.API_URL}/storeJourneys?store=${storeId}`,
		{
			headers: {
				...authHeader(token),
			},
		}
	)
		.then((res) => res.json());
};

export const loadProducts = (
	token: string,
	id: number
): Promise<JourneyProduct[]> => {
	return fetch(
		`${process.env.API_URL}/journeyProducts?journey=${id}`,
		{
			headers: {
				...authHeader(token),
			},
		}
	)
		.then((res) => res.json());
};


export function login(user: string, pw: string) {
	return fetch(
		`${process.env.API_URL}/auth/token`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username: user,
				password: pw,
			}),
		}
	)
		.then((res) => res.json());
}
