import type { FunctionComponent } from 'react';

import { css } from '@emotion/css';


const style = css`
	z-index: 899;
	cursor: pointer;

	i {
		font-size: 1.5rem;
	}
`;

interface Props {
	onButtonClick: () => void,
	open: boolean,
}

const InfoButton: FunctionComponent<Props> = ({ open, onButtonClick }) => {
	return(
		<div className={style}
			onClick={onButtonClick}>
			<i className="material-icons">
				{!open ? 'info' : 'highlight_off'}
			</i>
		</div>
	);
};

export default InfoButton;
