export const FALLBACK_COLOR = 'black';

export const FILTER_RESULTS_LIMIT_OPTIONS = [
	25,
	50,
	100,
	150,
	0 // all
];
export const DEFAULT_FILTER_RESULTS_LIMIT = 100;

export const ACCESS_TOKEN_LS = 'bboard-access-token';
export const REFRESH_TOKEN_LS = 'bboard-refresh-token';

export const actionTypes = {
	SET_STORE: 'SET_STORE',
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGOUT: 'LOGOUT',
	SET_AUTH_TOKENS: 'SET_AUTH_TOKENS',
	LOADING_DATA: 'LOADING_DATA',
	GET_STORE_JOURNEYS: 'GET_STORE_JOURNEYS',
};


export const visualizationColors: Record<string, string> = {
	grau: '#606060',
	braun: '#7e5621',

	pioneer: '#63a358',
	'grün': '#63a358',
	'grün/pioneer': '#63a358',

	blau: '#4f7ad3',
};
