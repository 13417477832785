import type { ChangeEvent, FunctionComponent } from 'react';
import type { Filter } from '../types';

import { useState, useEffect } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { css } from '@emotion/css';

import StyledRadio from './StyledRadio';
import Slides from './Slides';
import Slide from './Slide';
import BackendSlide from './BackendSlide';
import InfoButton from './InfoButton';
import PolarizedSlide from './filterContent/PolarizedSlide';
import ThicknessSlide from './filterContent/ThicknessSlide';
import DistanceSlide from './filterContent/DistanceSlide';
import PhototropSlide from './filterContent/PhototropSlide';
import DriveSafeSlide from './filterContent/DriveSafeSlide';
import QualityCompare_01Slide from './filterContent/QualityCompare_01Slide';
import QualityCompare_02Slide from './filterContent/QualityCompare_02Slide';
import PriceTable from './PriceTable';
import { parseFilterOptionValues } from '../helper/utils';


const filterSlideContents: Record<string, JSX.Element[]> = {
	typeOfGlas: [],
	coating: [],
	optimised: [
		<DriveSafeSlide key='driveSafe' />
	],
	thickness: [
		<ThicknessSlide key='thickness' />
	],
	quality: [
		<QualityCompare_01Slide key='quality-compare-01' />,
		<QualityCompare_02Slide key='quality-compare-02' />,
		<PriceTable key='price-table' />
	],
	distance: [
		<DistanceSlide key='distance' />
	],
	color: [],
	sunProtection: [
		<PolarizedSlide key='polarized' />,
		<PhototropSlide key='phototrop' />
	]
};


const SectionStyle = css`
	transition: height 0.4s ease-in-out;
	border-bottom: 1px solid black;
	margin: 0.2rem 1rem;
	overflow: hidden;
`;

const Row = css`
	font-size: 1.8rem;
	display: flex;
	align-items: space-between;

	.spacer {
		flex-grow: 1;
	}
`;

const Info = css`
	width: 16.667%;
	padding: 10px 0;
	display: flex;
`;

const OpenButton = css``;

const SlideShowName = css`
	padding: 14px 0 0;
`;


interface Props {
	filter: Filter,
	onChange: (filter: Filter, value: string) => void,
	disabled: boolean,
	value: string,
}

const FilterSection: FunctionComponent<Props> = ({
	filter,
	onChange,
	disabled,
	value,
}) => {
	const optionValues = parseFilterOptionValues(filter.values);

	const [isOpen, setIsOpen] = useState<boolean>(false);
	// const [checkValue, setCheckValue] = useState({});

	const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
		onChange(filter, event.target.value);
	};

	// const handleCheckChange = (name/*, index*/) => (event) => {
	// 	const checkedOptions = { ...checkValue, [name]: event.target.checked };
	// 	setCheckValue(checkedOptions);
	// 	onChange(thisFilter.productFieldName, thisFilter.label, checkedOptions);
	// 	!isOpen ? setIsOpen(true) : null;
	// };

	const sectionId = `#section_${filter.productFieldName}`;
	useEffect(() => {
		if (isOpen) {
			const elem = document.getElementById(sectionId);
			if (elem) {
				elem.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}, []);

	const slideShow = filter.slideShow || { slides: [] };
	let slides: JSX.Element[] = [];
	const contents = filterSlideContents[filter.productFieldName];
	if (contents && contents.length) {
		slides = contents.map((content, i) => {
			return <Slide
				key={`filter-info-slide-${i}`}
				content={content}
			/>;
		});
	}
	const backendSlides = slideShow.slides.map((slide, i) => {
		return <BackendSlide
			key={i}
			title={slide.title}
			text={slide.text || undefined}
			image={slide.image || undefined}
		/>;
	});
	slides = [
		...slides,
		...backendSlides
	];
	const showSlideShow = isOpen && slides.length > 0;

	// const availableFilterOptions = props.glasList.map((item, filterType) => {});

	return (
		<div className={SectionStyle} id={sectionId}>
			<div className={Row}>
				<div className={Info}>
					<h2>{filter.label}</h2>
				</div>

				{filter.type === 0
					? <RadioGroup
						aria-label="position"
						name="position"
						value={value}
						onChange={handleRadioChange}
						row
					>
						{optionValues.map((item) => {
							return (
								<FormControlLabel
									value={item}
									disabled={disabled ? true : false}
									key={item}
									classes={{ root: 'StyledControl' }}
									control={<StyledRadio />}
									label={item}
									labelPlacement="end"
								/>
							);
						})}
						<FormControlLabel
							value={''}
							key={'empty'}
							classes={{ root: 'StyledControl' }}
							control={<StyledRadio />}
							label={'keine Auswahl'}
							labelPlacement="end"
						/>
					</RadioGroup>
					: <h2 className={SlideShowName}>
						{slideShow ? slideShow.title : null}
					</h2>
				}
				<div className="spacer" />
				<div className={OpenButton}>
					{slides.length > 0
						? <InfoButton
							open={isOpen}
							onButtonClick={() => setIsOpen(!isOpen)}
						/>
						: null
					}
				</div>
			</div>

			{(showSlideShow)
				? <Slides slides={slides} />
				: null
			}
		</div>
	);
};

export default FilterSection;
