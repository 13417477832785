import type { FunctionComponent } from 'react';
import type { AuthState, StoreJourney, Store } from '../types';

import {
	// BrowserRouter as Router,
	HashRouter as Router,
	Route,
	Switch,
} from 'react-router-dom';

import About from '../components/About';
import Home from '../components/Home';
import Journey from '../components/Journey';
import Header from '../components/Header';
import StoreContext from '../context/store-context';
import JourneyContext from '../context/journey-context';


interface AppProps {
	auth: AuthState,
	store: Store,
	journeys: StoreJourney[],
}

const App: FunctionComponent<AppProps> = (props: AppProps) => {
	const journeys = props.journeys || [];

	return <StoreContext.Provider value={props.store}>
		<JourneyContext.Provider value={journeys}>
			<Router>
				<Header
					theme={props.store}
					journeys={journeys}
				/>
				<Switch>
					<Route
						exact
						path="/about"
						component={About}
					/>

					<Route
						exact
						path="/:journey"
						render={(_props) => {
							const journeyPath = _props.match.params.journey;
							const storeJourney = journeys.find(
								(j) => j.journey.pathname === journeyPath
							);
							if (!storeJourney) {
								return null;
							}
							return <Journey
								key={journeyPath}
								storeJourneyId={storeJourney.id}
								journey={storeJourney.journey}
								slideShow={storeJourney.slideShow || undefined}
								afterShow={storeJourney.afterShow || undefined}
								auth={props.auth}
							/>;
						}}
					/>

					<Route
						component={() => {
							return <Home
								theme={props.store}
								journeys={journeys}
								auth={props.auth}
							/>;
						}}
					/>
				</Switch>
			</Router>
		</JourneyContext.Provider>
	</StoreContext.Provider>;
};

export default App;
