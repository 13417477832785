import type { FunctionComponent } from 'react';

import { css } from '@emotion/css';
import { Link } from 'react-router-dom';


const style = css`
	padding: 2rem;
`;


interface Props {
	logoutHandler: () => void,
}

const LogoutButton: FunctionComponent<Props> = (props) => {
	return (
		<div className={style}>
			<Link
				onClick={props.logoutHandler}
				to={'/'}
			>Logout</Link>
		</div>
	);
};

export default LogoutButton;
