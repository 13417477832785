import ImageCompareSlide from './ImageCompareSlide';

const PolarizedSlide = () => <ImageCompareSlide
	title="Kontraststeigernde Gläser (polarisiert)"
	bodytext="Polarisierte Gläser filtern reflektiertes, diffuses Licht und ermöglichen so ein klare Sicht mit satteren, schöneren Farben und ohne irritierende Reflexe."
	images={[
		[
			'assets/img/filterContent/polarizedSmall_true.jpg',
			'assets/img/filterContent/polarizedSmall_false.jpg'
		],
	]}
	type="mouseFollow"
/>;

export default PolarizedSlide;
