import { css } from '@emotion/css';


const PageStyle = css`
	display: flex;
	flex-direction: column;
	align-content: space-between;
	width: 100%;
	padding: 1rem;

	h1 {
		font-size: 2rem;
		margin-bottom: 2rem;
	}

	h2 {
		font-size: 1.5rem;
		margin-bottom: 2rem;
	}
`;

const ContainerStyle = css`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
`;

const ItemStyle = css`
	padding: 1rem;
	margin-bottom: 2rem;
	transition: background-color 0.4s;

	&:hover {
		background-color: white;
	}

	div,
	img,
	h2 {
		margin: 1rem 0;
	}

	img {
		width: 100%;
		text-align: center;
	}
`;


const sides = [
	// {
	//   imgSrc: "assets/img/filterContent/driveSafe_01.jpg",
	//   label: "niedrige Qualität",
	//   text:"Kurzer Text (1-2 Zeilen) über die Nachteile schlechte Qualität.",
	// },
	{
		imgSrc: 'assets/img/filterContent/driveSafe_02.jpg',
		label: 'Verbesserte Sicht bei widrigen Lichtverhältnissen \n — dank speziellem Brillenglasdesign',
		text: 'Durch die Luminance Design® Technologie können Entfernungen und Verkehrsverhältnisse viel besser eingeschätzt werden - auch bei widrigen Lichtverhältnissen (z. B. bei Regen oder Dämmerung).',
	},
	{
		imgSrc: 'assets/img/filterContent/driveSafe_03.jpg',
		label: 'Geringeres Blendungsempfinden bei Gegenverkehr — dank innovativer Veredelung',
		text:'Die DuraVision® DriveSafe Veredelung reduziert gezielt das Blendungsempfinden, indem sie selektiv die Anteile von kurzwelligem Licht abschwächt, die für den Blendungseffekt verantwortlich sind.',
	},
];

// TODO: DRY *.Slide.js
const DriveSafeSlide = () => {
	return <div className={PageStyle}>
		<h1>ZEISS DriveSafe Brillengläser.</h1>
		<h2>Eine neue Brillenglas-Kategorie — für ganztägiges Tragen geeignet.</h2>
		<div className={ContainerStyle}>
			{sides.map((item, i) => (
				<div key={i} className={ItemStyle}>
					<img src={item.imgSrc} />
					<h2>{item.label}</h2>
					<p>{item.text}</p>
				</div>
			))}
		</div>
	</div>;
};

export default DriveSafeSlide;
