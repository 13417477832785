import type { FunctionComponent } from 'react';

import { css } from '@emotion/css';

import ActiveFiltersContext from '../../context/active-filters-context';
import { visualizationColors } from '../../constants';
import ColoredLens from './ColoredLens';


const ImgStyle = css`
	height: 100%;
	width: 100%;
	position: absolute;
	background-size: cover;
	top: 0;
	transition: background-position 600ms ease;
`;

const ImgStyle1 = css`
	z-index: 8;
`;

const ImgStyle2 = css`
	z-index: 9;
	mask-size: auto 120%;
	mask-repeat: no-repeat;
	opacity: 0;
	transition: opacity 2000ms ease-out 0.5s, background-position 600ms ease;
`;

const Frame = css`
	position: absolute;
	z-index: 20;
	width: 80% !important;
	left: 10%;
	top: -8%;
`;

interface Props {
	image1: string,
	image2: string,
	imageState: string,
	switchValues: string[],
	style: string,
}

const ImageCompareSwitch: FunctionComponent<Props> = ({
	image1,
	image2,
	imageState,
	switchValues,
	style,
}) => {
	// const [imageState, setImageState] = useState("")

	const BackgroundPosition = {
		backgroundPosition: (imageState == switchValues[1])
			? 'right center'
			: 'left center',
	};
	const ImageOpacity = {
		opacity: imageState == switchValues[1] ? '1' : '0',
	};
	const ColorOpacity = {
		opacity: imageState == switchValues[1] ? '.4' : '0',
	};

	return (
		<ActiveFiltersContext.Consumer>
			{(activeFilters) => {
				let c = '#ffffff';
				if (activeFilters.color) {
					const [/* label */, value] = activeFilters.color;
					c = visualizationColors[value] || c;
				}

				return <div
					className={css`
						${style}
						position: relative;
						overflow: hidden;
					`}
				>
					<div
						className={css`
							${ImgStyle}
							${ImgStyle1}
							${BackgroundPosition}
							background-image: url(${image2});
						`}
					/>
					<div
						className={css`
							${ImgStyle}
							${ImgStyle2}
							mask: url(assets/img/panton.svg);
							mask-size: auto 120%;
							mask-repeat: no-repeat;
							mask-position: 50% 40%;
							${ImageOpacity}
							${BackgroundPosition}
							background-image: url(${image1});
						`}
					/>
					<ColoredLens
						color={c}
						extraStyle={{
							...ColorOpacity,
							width: '80%',
							left: '10%',
							top: '-8%',
						}}
					/>
					<img
						className={Frame}
						src="assets/img/panton_frame.svg"
					/>
				</div>;
			}}
		</ActiveFiltersContext.Consumer>
	);
};

export default ImageCompareSwitch;
