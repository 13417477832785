import { css } from '@emotion/css';
import { arrowStyle } from '../../styles';


// TODO: DRY
const pageStyle = css`
	display: flex;
	flex-direction: column;
	align-content: space-between;
	width: 100%;
	padding: 1rem;

	h1 {
		font-size: 2rem;
		margin-bottom: 2rem;
	}
`;

const containerStyle = css`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
`;

const itemStyle = css`
	text-align: center;
	text-align: -webkit-center;
	padding: 1rem;
	transition: background-color 0.4s;

	&:hover {
		background-color: white;
	}

	div,
	img,
	h2 {
		margin: 1rem 0;
	}

	img {
		height: 500px;
	}
`;

const qualitiesStyle = css`
	margin-bottom: 3rem;

	ul {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
`;

const sides = [
	{
		imgSrc: 'assets/img/filterContent/quality_compare_01_01.png',
		label: 'niedrige Qualität',
		text:'Kurzer Text (1-2 Zeilen) über die Nachteile schlechte Qualität.',
	},
	{
		imgSrc: 'assets/img/filterContent/quality_compare_01_02.png',
		label: 'hohe Qualität',
		text:'Kurzer Text (1-2 Zeilen) über die Vorteile guter Qualität.',
	},
];

const QualityCompare_01 = () => {
	return(
		<div className={pageStyle}>
			<h1>Bildqualität Gleitsichtgläser</h1>
			<div className={qualitiesStyle}>
				<ul>
					<li>Standard</li>
					<li>Komfort</li>
					<li>Premium</li>
					<li>Individual</li>
				</ul>
				<div className={arrowStyle}>
					<span className="arrowbody"></span>
					<span className="arrowhead"></span>
				</div>
			</div>
			<div className={containerStyle}>
				{sides.map((item, i) => (
					<div key={i} className={itemStyle}>
						<img src={item.imgSrc} />
						<h2>{item.label}</h2>
						<p>{item.text}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default QualityCompare_01;
