import type { FunctionComponent } from 'react';
import type { StoreJourney } from '../types';

import { css } from '@emotion/css';
import { Link } from 'react-router-dom';


const NaviStyle = css`
	padding: 2rem;
	li {
		padding: 0.5em 0.4rem;
	}
`;

interface Props {
	onClick: () => void,
	journeys: StoreJourney[],
}

const Navigation: FunctionComponent<Props> = ({ onClick, journeys }) => {
	return (
		<ul className={NaviStyle}>
			{journeys.map((item) =>
				<li key={item.journey.pathname} >
					<Link
						onClick={onClick}
						to={`/${item.journey.pathname}`}
					>
						{item.journey.label}
					</Link>
				</li>)
			}
		</ul>
	);
};

export default Navigation;
