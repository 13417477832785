import ImageCompareSlide from './ImageCompareSlide';

const PhototropSlide = () => <ImageCompareSlide
	title="Selbsttönende Gläser (phototrop)"
	bodytext="Bei Sonneneinstrahlung verdunkeln sich die Gläser automatisch bis zu 80%. Im Innenbereich ungetönt, draußen mit Sonnenschutz."
	images={[
		[
			'assets/img/filterContent/phototrop_on_nocolor.jpg',
			'assets/img/filterContent/phototrop_off_nocolor.jpg'
		],
	]}
	switchValues={['innen', 'außen']}
	type="switch"
/>;

export default PhototropSlide;
