import type { FunctionComponent } from 'react';

import { css } from '@emotion/css';
import { useState, useEffect } from 'react';

import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';


const SliderStyle = css`
	.backArrow,
	.forwardArrow {
	}

	.backArrow > i,
	.forwardArrow > i {
		font-size: 3rem;
		cursor: pointer;
	}

	.slide {
		min-width: calc(100%);
	}
`;

const SliderWrapperStyle = css`
	display: flex;
	flex: row wrap;
	transition: "transform ease-out 0.45s";
	margin-top: 0.5rem;
`;

const SliderNavigation = css`
	display: flex;
	flex: row wrap;
	justify-content: space-between;
`;

const Counter = css`
	font-size: 2rem;
	align-self: center;
`;


const slideWidth = () => {
	const elem = document.querySelector('.slide');
	if (!elem) { return 0; }
	return elem.clientWidth;
};


interface Props {
	slides: JSX.Element[],
}

const Slides: FunctionComponent<Props> = ({
	slides = []
}) => {
	const [translateValue, setTranslateValue] = useState(0);
	const [slideIndex, setSlideIndex] = useState(0);

	useEffect(() => {
		if (Math.abs(slideIndex) === slides.length) {
			setSlideIndex(0);
			setTranslateValue(0);
		}
	}), [slideIndex];

	const goToNextSlide = () => {
		setSlideIndex(slideIndex + 1);
		setTranslateValue(translateValue + (slideWidth() * -1));
	};

	const goToPrevSlide = () => {
		if (slideIndex > 0) {
			setSlideIndex(slideIndex - 1);
			setTranslateValue(translateValue + slideWidth());
		} else {
			setSlideIndex(0);
		}
	};

	const leftArrowStyle: React.CSSProperties = {
		opacity: (slideIndex < 1) ? '.2' : '1',
	};
	const rightArrowStyle = {
		opacity: (slideIndex === slides.length - 1) ? '.2' : '1',
	};

	return (
		<div className={`${SliderStyle} sliderContainer`}>
			<div
				className={css`
					${SliderNavigation}
					${slides.length > 1 ? '' : 'display: none'}
				`}
			>
				<LeftArrow
					style={leftArrowStyle}
					goToPrevSlide={() => goToPrevSlide()}
				/>
				<div className={Counter}>
					{slideIndex + 1}/{slides.length}
				</div>
				<RightArrow
					style={rightArrowStyle}
					goToNextSlide={() => goToNextSlide()}
				/>
			</div>
			<div className={`${SliderWrapperStyle} slider-wrapper`}
				style={{
					transform: `translateX(${translateValue}px)`,
					transition: 'transform ease-out 0.4s'
				}}
			>
				{slides}
			</div>
		</div>
	);
};

export default Slides;
