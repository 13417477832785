import type { FunctionComponent } from 'react';
import type { JourneyProduct } from '../types';

import { css } from '@emotion/css';

import ProductsContext from '../context/products-context';
import { formatPrice, formatPriceRange } from '../helper/utils';


const containerStyle = css`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	h1 {
		font-size: 2rem;
	}
	p {
		font-size: 1rem;
	}
`;

const tableStyle = css`
	font-size: 1rem;
	width: 100%;
	thead {
		text-align: right;
		padding-bottom:.5rem;
		border-bottom: 1px solid black;
	}
	tbody {
		text-align: right;
		background-color: rgba(0, 0, 0, 0.1);
		}
	}
	tbody td {
		padding: 1rem .5rem;
	}

	th {
		div {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			h2 {
				display: inline-block;
			}
		}
	}
`;

const weak = css`
	opacity: .3;
`;

const headerStyle = css`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	h2 {
		margin: 1rem;
	}
`;

const logoStyle = css`
	height: 2rem;
	margin: .5rem 1rem;
	width: auto;
`;


const getTotalPriceRange = (products: JourneyProduct[]) => {
	const prices = products.map((p) => p.calculated_price);
	if (!prices.length) {
		return '————';
	}

	const minPrice = Math.min(...prices);
	const maxPrice = Math.max(...prices);
	if (minPrice === maxPrice) {
		return formatPrice(minPrice);
	}

	return formatPriceRange(minPrice, maxPrice);
};


// TODO: DRY
const getPriceRange = (
	products: JourneyProduct[],
	thickness: number,
	producerIds: number[]
) => {
	const prices = [];
	for (let i = 0; i < products.length; i++) {
		const p = products[i];
		if (p.product.index == thickness &&
			producerIds.includes(p.product.producer)
		)
			prices.push(p.calculated_price);
	}
	const minPrice = Math.min(...prices);
	const maxPrice = Math.max(...prices);
	if (prices.length > 0 && minPrice !== maxPrice) {
		return formatPriceRange(minPrice, maxPrice);
	} else if (prices.length > 0 && minPrice === maxPrice) {
		return formatPrice(minPrice);
	} else {
		return null;
	}
};


interface Props {
	glasList: JourneyProduct[],
}

const PriceTable: FunctionComponent<Props> = ({ glasList }) => {
	return (
		<ProductsContext.Consumer>
			{(products) =>
				<div className={containerStyle}>
					<div className={headerStyle}>
						<h1>Preisübersicht</h1>
						<h2>{getTotalPriceRange(glasList)}</h2>
						<p>Alle Preise beziehen sich auf das Glaspaar.</p>
					</div>
					<table className={tableStyle}>
						<thead>
							<tr>
								<th>
									<div>
										<h2>Glas Index</h2>
									</div>
								</th>
								<th>
									<div>
										<img
											className={logoStyle}
											src="assets/img/zeiss-logo-rgb.png"
										/>
										<h2>ZEISS</h2>
									</div>
								</th>
								<th>
									<div>
										<img
											className={logoStyle}
											src="assets/img/germany-flag-square-medium.png"
										/>
										<h2>dt. Qualitätsgläser</h2>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1.74</td>
								{getPriceRange(glasList, 1.74, [0])
									? <td>{getPriceRange(glasList, 1.74, [0])}</td>
									: <td className={weak}>{getPriceRange(products, 1.74, [0])}</td>}
								{getPriceRange(glasList, 1.74, [1, 2])
									? <td>{getPriceRange(glasList, 1.74, [1, 2])}</td>
									: <td className={weak}>{getPriceRange(products, 1.74, [1, 2])}</td>}
							</tr>
							<tr>
								<td>1.67</td>
								{getPriceRange(glasList, 1.67, [0])
									? <td>{getPriceRange(glasList, 1.67, [0])}</td>
									: <td className={weak}>{getPriceRange(products, 1.67, [0])}</td>}
								{getPriceRange(glasList, 1.67, [1, 2])
									? <td>{getPriceRange(glasList, 1.67, [1, 2])}</td>
									: <td className={weak}>{getPriceRange(products, 1.67, [1, 2])}</td>}
							</tr>
							<tr>
								<td>1.6</td>
								{getPriceRange(glasList, 1.6, [0])
									? <td>{getPriceRange(glasList, 1.6, [0])}</td>
									: <td className={weak}>{getPriceRange(products, 1.6, [0])}</td>}
								{getPriceRange(glasList, 1.6, [1, 2])
									? <td>{getPriceRange(glasList, 1.6, [1, 2])}</td>
									: <td className={weak}>{getPriceRange(products, 1.6, [1, 2])}</td>}
							</tr>
							<tr>
								<td>1.5</td>
								{getPriceRange(glasList, 1.5, [0])
									? <td>{getPriceRange(glasList, 1.5, [0])}</td>
									: <td className={weak}>{getPriceRange(products, 1.5, [0])}</td>}
								{getPriceRange(glasList, 1.5, [1, 2])
									? <td>{getPriceRange(glasList, 1.5, [1, 2])}</td>
									: <td className={weak}>{getPriceRange(products, 1.5, [1, 2])}</td>}
							</tr>
						</tbody>
					</table>
				</div>
			}
		</ProductsContext.Consumer>
	);
};

export default PriceTable;
