import type { FunctionComponent } from 'react';
import type {
	ActiveFilters,
	AuthState,
	Filter,
	JourneyProduct,
	JourneyType,
	SlideShow
} from '../types';

import { useState, useEffect } from 'react';
import * as R from 'ramda';

import ProductsContext from '../context/products-context';
import ActiveFiltersContext from '../context/active-filters-context';
import { loadProducts } from '../api';
import InfoSection from './InfoSection';
import ExtraSection from './ExtraSection';
import Intro from './Intro';
import FilterSection from './FilterSection';
import Selection from './Selection';
import { determineDisabledFilters, filterProducts } from '../helper/utils';


// sort filters in journey for importance
// map for efficient lookup of sort index
const sortOrder = [
	'werte',
	'typeOfGlas',
	'distance',
	'quality',
	'optimised',
	'thickness',
	'sunProtection',
	'color',
	'coatings',
];
const sortPriorities: Record<string, number> = {};
sortOrder.forEach((fieldName, i) => {
	sortPriorities[fieldName] = i;
});


interface Props {
	storeJourneyId: number,
	journey: JourneyType,
	slideShow?: SlideShow,
	afterShow?: SlideShow,
	auth: AuthState,
}

const Journey: FunctionComponent<Props> = ({
	auth,
	storeJourneyId,
	journey,
	slideShow,
	afterShow
}) => {
	const [activeFilters, setActiveFilters] = useState<ActiveFilters>({});
	const [disabledFilters, setDisabledFilters] = useState<string[]>([]);
	const [filteredProducts, setFilteredProducts] = useState<JourneyProduct[]>([]);
	const [allJourneyProducts, setAllJourneyProducts] = useState<JourneyProduct[]>([]);

	useEffect(
		() => {
			if (!auth.access) { return; }
			loadProducts(auth.access, storeJourneyId).then((products) => {
				setAllJourneyProducts(products);
			});
		},
		[]
	);

	useEffect(
		() => {
			setFilteredProducts(
				filterProducts(allJourneyProducts, activeFilters)
			);
		},
		[allJourneyProducts, activeFilters]
	);

	const handleFilterChange = (filter: Filter, value: string) => {
		const { productFieldName, label } = filter;

		const filters: ActiveFilters = R.assoc(
			productFieldName,
			[label, value],
			activeFilters
		);

		// remove active filters without a value selected
		Object.keys(filters).forEach((key) => {
			const [/* label */, val] = filters[key];
			if (val === '') {
				delete filters[key];
			}
		});

		// figure out which filters need to be disabled, based on the
		// current filter settings
		const disabled = determineDisabledFilters(filters, disabledFilters);

		// newly disabled filters might already be selected / active
		disabled.forEach((key) => {
			delete filters[key];
		});

		setActiveFilters(filters);
		setDisabledFilters(disabled);
	};

	journey.filters.sort((a, b) => {
		return (
			(sortPriorities[a.productFieldName] - sortPriorities[b.productFieldName]) ||
			// in case of a tie: alphabetically
			a.productFieldName.localeCompare(b.productFieldName)
		);
	});

	return (
		<ProductsContext.Provider value={allJourneyProducts}>
			<ActiveFiltersContext.Provider value={activeFilters}>
				<div>
					<Intro journey={journey} />
					{slideShow
						? <InfoSection
							journey={journey}
							slideShow={slideShow}
						/>
						: null
					}
					{journey.filters.map((filter) => {
						return <FilterSection
							key={filter.productFieldName}
							filter={filter}
							value={activeFilters[filter.productFieldName]?.[1] || ''}
							disabled={disabledFilters.includes(filter.productFieldName)}
							onChange={handleFilterChange}
						/>;
					})}
					{afterShow
						? <ExtraSection
							journey={journey}
							slideShow={afterShow}
						/>
						: null
					}
					<Selection
						products={filteredProducts}
						activeFilters={activeFilters}
					/>
				</div>
			</ActiveFiltersContext.Provider>
		</ProductsContext.Provider>
	);
};

export default Journey;
