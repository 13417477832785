import type { FunctionComponent } from 'react';

import { css, cx } from '@emotion/css';


const styles = css`
	position: relative;
	background: linear-gradient(0deg, rgba(0, 0, 0, 10%), rgba(0, 0, 0, 10%));

	ol {
		display: block;
		list-style-type: decimal;
		margin-left: 0;
		margin-right: 0;
		padding-left: 40px;
	}

	p {
		width: 100%;
	}
`;


interface Props {
	content: JSX.Element
}

const Slide: FunctionComponent<Props> = (props) => {
	return <div className={cx(styles, 'slide')}>
		{props.content}
	</div>;
};

export default Slide;
