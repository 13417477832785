import type { ChangeEvent, FunctionComponent } from 'react';

import { useState, useEffect } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { css } from '@emotion/css';

import StyledRadio from '../StyledRadio';
import ImageCompareSwitch from './ImageCompareSwitch2';
import ImageCompareMouseFollow from './ImageCompareMouseFollow';


const Style = css`
	p,
	h1 {
		margin: 1rem;
	}

	h1 {
		font-size: 2rem;
		line-height: 1.4;
	}

	p {
		float: left;
		line-height: 1.4;
		font-weight: 300;
	}
`;

const Image = css``;

const ContextContainer = css`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	div {
		display: flex;
		flex-direction: column;
	}
`;

const ImageStyle = css`
	width: 900px;
	height: 600px;
	margin: 1rem;
`;

const SwitchControl = css`
	margin: 1rem;
`;


interface Props {
	title: string,
	bodytext: string,
	type: string,
	switchValues?: string[],
	images: string[][],
}

const ImageCompareSlide: FunctionComponent<Props> = ({
	title,
	bodytext,
	type,
	images,
	switchValues = []
}) => {
	const [value, setValue] = useState('');
	const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	};

	useEffect(
		() => {
			if (switchValues.length) {
				setValue(switchValues[0]);
			}
		},
		[]
	);

	return (
		<div className={Style}>
			<h1>{title}</h1>
			<div className={ContextContainer}>
				<div>
					<p>{bodytext}</p>
					{type == 'switch'
						? <RadioGroup
							className={SwitchControl}
							aria-label="position"
							name="position"
							value={value}
							onChange={handleRadioChange}
							style={{ justifyContent: 'center' }}
						>
							{switchValues.map((item) => {
								return (
									<FormControlLabel
										value={item}
										key={item}
										classes={{ root: 'StyledControl' }}
										control={<StyledRadio/>}
										label={item}
										labelPlacement="end"
									/>
								);
							})}
						</RadioGroup>
						: null
					}
				</div>
				<div className={Image}>
					{type == 'switch'
						? <ImageCompareSwitch
							switchValues={switchValues}
							style={ImageStyle}
							imageState={value}
							image1={images[0][0]}
							image2={images[0][1]}
						/>
						: <ImageCompareMouseFollow
							style={ImageStyle}
							image1={images[0][0]}
							image2={images[0][1]}
						/>
					}
				</div>
			</div>
		</div>
	);
};

export default ImageCompareSlide;
