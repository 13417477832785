import type { FunctionComponent } from 'react';

import { css } from '@emotion/css';


const svgStyle = css`
	position: absolute;
	z-index: 20;
	mix-blend-mode: color;
	transition: opacity 2000ms ease-out 0.5s;
	enable-background: new 0 0 100 100;
`;


interface Props {
	extraStyle: React.CSSProperties,
	color: string,
}


const ColoredLens: FunctionComponent<Props> = ({
	extraStyle,
	color,
}) => {
	return <svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 100 100"
		className={svgStyle}
		style={extraStyle}
	>
		<path
			className={css`
				fill: ${color};
				stroke: none;
				transition: fill 400ms ease-out;
			`}
			d="M50,89.2c-25.8,0-48.5-23-48.5-49.2C1.5,18.6,17.8,7.7,50,7.7c32.2,0,48.5,10.9,48.5,32.3C98.5,66.2,75.8,89.2,50,89.2z"
		/>
	</svg>;
};

export default ColoredLens;
