import type {
	DatabaseEnumLookupTable,
	JourneyProduct,
	Product,
} from '../types';

import {
	qualities,
	// distances,
	colors,
	productLineNames,
	getSunProtectionDbValues,
} from './DBtranslate';


export const stringFilterFunction = (
	productValue: number | string,
	filterValue: string
) => {
	const productVal = productValue.toString();
	if (filterValue === '') { return true; }
	return (productVal === filterValue);
};


export const genericFilterFunction = (
	productValue: number,
	filterValue: string,
	valueTranslation: DatabaseEnumLookupTable
) => {
	return stringFilterFunction(
		valueTranslation[productValue],
		filterValue
	);
};


export const higherValuesFilterFunction = (
	productName: string,
	filterValue: string
) => {
	const filterVal = filterValue.toLowerCase();
	if (filterVal === '') { return true; }
	const name = productName.toLowerCase();
	const isMatch = (
		!name.includes('aktion') &&
		!name.includes('lager')
	);
	return isMatch;
};


export const distanceFilterFunction = (productName: string, filterValue: string) => {
	if (filterValue === '') { return true; }
	const isMatch = (
		productName.includes(filterValue) ||
		(productName.includes('Tact') && filterValue === 'Room') ||
		(productName.includes('Lecture') && filterValue === 'Near') ||
		(productName.includes('Addpower') && filterValue === 'Book')
	);
	return isMatch;
};


export const colorFilterFunction = (
	product: Product,
	filterValue: string,
	valueTranslation: DatabaseEnumLookupTable
) => {
	if (filterValue === '') { return true; }
	const { color, nameCode } = product;
	// if (!product.coatingCode) { return false; }
	const coatingCode = product.coatingCode || '';
	const isMatch = (
		(valueTranslation[color] === filterValue) ||
		(valueTranslation[color] === 'pioneer' && filterValue === 'grün/pioneer') ||
		(valueTranslation[color] === 'grün' && filterValue === 'grün/pioneer') ||
		(nameCode.endsWith('RG') && filterValue === 'grau') ||
		(nameCode.endsWith('GG') && filterValue === 'grün/pioneer') ||
		(nameCode.endsWith('BR') && filterValue === 'braun') ||
		(coatingCode.endsWith('4B') && filterValue === 'braun') ||
		(coatingCode.endsWith('4G') && filterValue === 'grau') ||
		(coatingCode.endsWith('4U') && filterValue === 'grün/pioneer')
	);
	return isMatch;
};


export const isBlaulichtSchutz = (p: Product) => {
	const coating = p.coating.toLowerCase();
	return (
		coating.includes('bc')
		// TODO: what about name == 'blueguard'?
	);
};


export const isDriveSafe = (p: Product) => {
	const name = p.name.toLowerCase();
	return (
		name.includes('drivesafe')
		// coating.includes('drivesafe')
	);
};


export const isBlueGuard = (p: Product) => {
	const name = p.name.toLowerCase();
	return name.includes('blueguard');
};


export const optimizedFilterFunction = (
	p: Product,
	filterValue: string
) => {
	if (filterValue === '') {
		return true;
	}

	if (filterValue === 'Blaulicht Schutz') {
		return isBlaulichtSchutz(p);
	}

	if (filterValue === 'DriveSafe') {
		return isDriveSafe(p);
	}

	if (filterValue === 'BlueGuard') {
		return isBlueGuard(p);
	}

	return false;
};


export const lensTypeFilterFunction = (
	productName: string,
	filterValue: string,
	productLines: Record<number, string[]>
) => {
	if (filterValue === '') { return true; }

	// Hilux Eyas 2.0 1.6 Aktion inkl. Sensity
	// Balansis
	// ...
	const nameParts = productName.split(' ');
	const nameBase = nameParts[0];

	const hoyaLines = productLines[0];
	const zeissLines = productLines[1];

	// TODO: what about 'gleichtsicht' for zeiss?
	// TODO: what about 'einstaerken' for hoya?
	const isMatch = (
		productName.includes(filterValue) ||
		(hoyaLines.includes(nameBase) && filterValue === 'Gleitsicht') ||
		(zeissLines.includes(nameBase) && filterValue === 'Einstärken')
	);
	return isMatch;
};


export const sunProtectionFilterFunction = (
	productValue: number,
	filterValue: string
) => {
	const translatedFilterValues = getSunProtectionDbValues(filterValue);
	const isMatch = translatedFilterValues.includes(productValue);
	return isMatch;
};


type TestFunctionsType = Record<string, (a: JourneyProduct, b: string) => boolean>;

export const testFunction: TestFunctionsType = {
	werte: (p: JourneyProduct, value: string) => {
		return higherValuesFilterFunction(p.product.name, value);
	},

	typeOfGlas: (p: JourneyProduct, value: string) => {
		return lensTypeFilterFunction(p.product.name, value, productLineNames);
	},

	quality: (p: JourneyProduct, value: string) => {
		return genericFilterFunction(p.product.quality, value, qualities);
	},

	distance: (p: JourneyProduct, value: string) => {
		return distanceFilterFunction(p.product.name, value/* , distances */);
	},

	color: (p: JourneyProduct, value: string) => {
		return colorFilterFunction(p.product, value, colors);
	},

	sunProtection: (p: JourneyProduct, value: string) => {
		return sunProtectionFilterFunction(p.product.protection, value);
	},

	thickness: (p: JourneyProduct, value: string) => {
		return stringFilterFunction(p.product.index, value);
	},

	// coatings: (p: JourneyProduct, value: string) => {
	//   const coating = p.product.coating.toLowerCase();
	//   const name = p.product.name.toLowerCase()
	//   const v = value.toLowerCase();
	//   return (coating.includes(v) || name.includes(v));
	// },

	optimised: (p: JourneyProduct, value: string) => {
		return optimizedFilterFunction(p.product, value);
	},

	// producer
	// ...
};
