import type { AnyAction, Reducer } from 'redux';
import type { AuthState } from '../types';

import jwtDecode from 'jwt-decode';
import { ACCESS_TOKEN_LS, actionTypes, REFRESH_TOKEN_LS } from '../constants';


const accessToken = localStorage.getItem(ACCESS_TOKEN_LS);
const refreshToken = localStorage.getItem(REFRESH_TOKEN_LS);
let loggedIn = Boolean(accessToken && refreshToken);
if (accessToken) {
	const { exp } = jwtDecode(accessToken) as { exp: number };
	const expirationDate = new Date(exp * 1000);
	if (expirationDate.getTime() <= Date.now()) {
		loggedIn = false;
	}
}
if (!loggedIn) {
	localStorage.removeItem(ACCESS_TOKEN_LS);
	localStorage.removeItem(REFRESH_TOKEN_LS);
}


const initialState: AuthState = {
	loggedIn,
	access: (loggedIn && accessToken) || undefined,
	refresh: (loggedIn && refreshToken) || undefined,
};


const authReducer: Reducer<AuthState, AnyAction> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case actionTypes.SET_AUTH_TOKENS: {
			state = {
				loggedIn: true,
				access: action.access,
				refresh: action.refresh,
			};
			break;
		}

		case actionTypes.LOGOUT: {
			state = {
				loggedIn: false,
				access: undefined,
				refresh: undefined,
			};
			break;
		}
	}
	return state;
};

export default authReducer;
