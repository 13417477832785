import { css } from '@emotion/css';


export const arrowStyle = css`
	width: calc(100% - 2rem);
	padding: 0 7rem;
	position: absolute;
	text-align: end;

	span {
		position: relative;
		display: inline-block;
	}

	span.arrowbody {
		width: 100%;
		height: 2px;
		top: 15px;
		background-color: black;
	}

	span.arrowhead {
		width: 16px;
		height: 16px;
		transform: rotate(45deg);
		border-top: 2px solid black;
		border-right: 2px solid black;
	}
`;
