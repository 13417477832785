import type { FunctionComponent } from 'react';
import type { JourneyType } from '../types';

import { css, keyframes } from '@emotion/css';
import { useState } from 'react';

import ScrollToArrow from './ScrollToArrow';


const imgStyle = css`
	position: absolute;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: center center;
`;

const imgStyle1 = css`
	z-index: 8;
	opacity: 0.9;
`;

const imgStyle2 = css`
	z-index: 9;
	top: 0;
	mask-size: auto 140%;
	mask-repeat: no-repeat;
	mask-position: 50% 50%;
`;

const headingStyle = css`
	position: absolute;
	padding: 10px;
	z-index: 10;
	color: white;
`;

const zoomAni = keyframes`
	0%, 50% {
		mask-size: auto 0%;
		animation-timing-function: ease-out;}

	100% {
		mask-size: auto 140%;
	}
`;

const ani = css`
	animation: ${zoomAni} 2000ms;
`;


interface Props {
	journey: JourneyType,
}

const Intro: FunctionComponent<Props> = ({ journey }) => {
	const [isDown, setIsDown] = useState(false);

	const scrollUpOrDown = () => {
		setIsDown(!isDown ? true : false);
		window.scrollTo({
			top: isDown ? 0 : document.body.scrollHeight,
			behavior: 'smooth'
		});
	};

	return (
		<div
			className={css`
				position: relative;
				overflow: hidden;
				height: 90vh;
				background: linear-gradient(-30deg, lightgrey, white);
			`}
		>
			<h1 className={headingStyle}>
				{journey.label}
			</h1>
			<ScrollToArrow
				down={isDown}
				onButtonClick={() => scrollUpOrDown()}
			/>
			<div
				className={css`
					${imgStyle}
					background-color: black;
				`}
			/>
			<div
				className={css`
					${imgStyle}
					${imgStyle1}
					background-image: url(${journey.image2});
				`}
			/>
			<div
				className={css`
					${imgStyle}
					${imgStyle2}
					${ani}
					mask: url(assets/img/${journey.pathname == 'kontakt' ? 'circle_100.svg' : 'panton.svg'});
					mask-size: auto 140%;
					mask-repeat: no-repeat;
					mask-position: 50% 50%;
					background-image: url(${journey.image1});
				`}
			/>
		</div>
	);
};

export default Intro;
