import type { LoginPayload, Store } from './types';
import type { AppThunk } from './store';

import { loadJourneys, loadStores, login } from './api';
import { ACCESS_TOKEN_LS, actionTypes, REFRESH_TOKEN_LS } from './constants';


export function loginRequest(
	user: string,
	password: string,
	cb: (err: Error | null, data?: LoginPayload) => void
) {
	login(user, password)
		.catch(cb)
		.then((data) => {
			if (!data.access || !data.refresh) {
				return cb(new Error('login failed'));
			}
			localStorage.setItem(ACCESS_TOKEN_LS, data.access);
			localStorage.setItem(REFRESH_TOKEN_LS, data.refresh);
			cb(null, data);
		});
	return {
		type: actionTypes.LOGIN_REQUEST,
	};
}


export function setAuthTokens(access: string, refresh: string) {
	return {
		type: actionTypes.SET_AUTH_TOKENS,
		access,
		refresh,
	};
}


export function logout() {
	localStorage.removeItem(ACCESS_TOKEN_LS);
	localStorage.removeItem(REFRESH_TOKEN_LS);
	return {
		type: actionTypes.LOGOUT,
	};
}


export function setStore(store: Store) {
	return {
		type: actionTypes.SET_STORE,
		payload: store
	};
}


export const loadData = (accessToken: string): AppThunk => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.LOADING_DATA,
		});
		loadStores(accessToken).then((stores) => {
			if (!stores || !stores.length) {
				console.error('failed to load stores');
				return;
			}
			const selectedStore = stores[0];
			dispatch(
				setStore(selectedStore)
			);

			loadJourneys(accessToken, selectedStore.id).then((journeys) => {
				dispatch({
					type: actionTypes.GET_STORE_JOURNEYS,
					payload: journeys
				});
			});
		});
	};
};
