import type { AnyAction } from 'redux';
import type { ThunkAction, ThunkMiddleware } from 'redux-thunk';

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import storeReducer from './reducers/storeReducer';
import journeysReducer from './reducers/journeysReducer';
import authReducer from './reducers/authReducer';


const rootReducer = combineReducers({
	store: storeReducer,
	journeys: journeysReducer,
	auth: authReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

const logger = createLogger({
	timestamp: false,
	collapsed: true,
});

const store = createStore(
	rootReducer,
	// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	applyMiddleware(
		thunk as ThunkMiddleware<RootState, AnyAction>,
		logger
	)
);

export default store;
export type DispatchType = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
