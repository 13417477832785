import type { FunctionComponent } from 'react';

import { css, cx, keyframes } from '@emotion/css';

import JourneyContext from '../context/journey-context';
import Navigation from './Navigation';
import LogoutButton from './LogoutButton';


const containerStyle = css`
	position: fixed;
	width: 50vw;
	height: 50vh;
	background: white;
	right: 25vw;
	top: 15vw;
	border-radius: 4px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 30%);
`;

const SlideIn = keyframes`
	0% { top: -50vh; opacity: 0; }
	100% { top: 15vh; opacity: 1; }
`;

const animation = css`
	animation: ${SlideIn} 0.2s ease-out;
`;


interface Props {
	logoutHandler: () => void,
	onClick: () => void,
	open: boolean,
}

const Menu: FunctionComponent<Props> = ({ open, onClick, logoutHandler }) => {
	return(
		<JourneyContext.Consumer>
			{(journeys) =>
				<div>
					{open ?
						<div className={cx(containerStyle, animation)}>
							<Navigation
								journeys={journeys}
								onClick={onClick}
							/>
							<LogoutButton
								logoutHandler={logoutHandler}
							/>
						</div>
						: null
					}
				</div>
			}
		</JourneyContext.Consumer>
	);
};

export default Menu;
