export const qualities: Record<number, string> = {
	0: 'Basic',
	1: 'Komfort',
	2: 'Premium',
	3: 'Individual',
};

export const distances: Record<number, string> = {
	0: 'Book',
	1: 'Near',
	2: 'Room',
};

export const sunProtections: Record<number, string> = {
	0: 'AdaptiveSun',
	1: 'AdaptiveSun polarisierend',
	2: 'phototrop',
	3: 'polarisierend',
	4: 'farbig',
	5: 'Lager',
	6: 'farbig / Lager',
	7: 'phototrop / Lager',
};

export function getSunProtectionDbValues(filterValue: string) {
	// TODO: use constants
	if (filterValue === 'polarisierend') {
		return [3];
	} else if (filterValue === 'phototrop') {
		return [0, 1, 2, 7];
	} else if (filterValue === 'pol/photo') {
		return [1];
	} else if (filterValue === 'getönt') {
		return [4, 6];
	} else if (filterValue === 'klar') {
		return [5, null];
	} else
		return [0, 1, 2, 3, 4, 5, 6, 7, 8, null];
}

export const colors: Record<number, string> = {
	0: 'braun',
	1: 'grau',
	2: 'pioneer',
	3: 'grün',
	4: 'blau',
	5: 'Uni Farben / Farbverlauf',
};

export const manufacturer: Record<number, string> = {
	0: 'Zeiss',
	1: 'Hoya',
	2: 'SYNCHRONY',
};

export const productLineNames = {
	// TODO: hoya?
	0: ['Hoyalux', 'Balansis', 'Daynamic', 'LifeStyle', 'MyStyle', 'Amplitude'],
	// TODO: zeiss?
	1: ['Nulux', 'Hilux', 'Sync']
};
