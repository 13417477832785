import type { FunctionComponent } from 'react';

import { css } from '@emotion/css';


const pageStyle = css`
	display: flex;
	flex-direction: column;
	align-content: space-between;
	width: 100%;
	padding: 1rem;

	h1 {
		font-size: 2rem;
		margin-bottom: 2rem;
	}
`;

const containerStyle = css`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
`;

const itemStyle = css`
	text-align: center;
	text-align: -webkit-center;
	transition: background-color 0.4s;
	width: 18%;

	&:hover {
		background-color: white;
	}

	div,
	img,
	h2 {
		margin: 1rem auto;
		padding: 1rem 0;
	}
`;

const legendStyle = css`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	h2 {
		margin: 1rem;
		line-height: 1.4em;

		&:nth-child(1) {
			flex-grow: 2;
		}

		&:nth-child(2) {
			flex-grow: 1;
		}
	}
`;

const indices = [
	{
		label: '1.6',
		imgSrc: 'assets/img/indices_16_15.svg',
		weight: 3,
	},
	{
		label: '1.67',
		imgSrc: 'assets/img/indices_167_15.svg',
		weight: 2,
	},
	{
		label: '1.74',
		imgSrc: 'assets/img/indices_174_15.svg',
		weight: 1,
	},
];

const iconStyle = css`
	background: url("assets/img/feather.svg");
	background-repeat: repeat-x;
	height: 2rem;
`;


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const ThicknessSlides: FunctionComponent<Props> = () => {
	return(
		<div className={pageStyle}>
			<h1>Die Vor-/ und Nachteile verschiedener Materialstärken</h1>
			<div className={containerStyle}>
				<div className={legendStyle}>
					<h2>Querschnitt<br/>des Glases</h2>
					<h2>Querschnitt<br/>im Vergleich<br/>mit Glasindex 1.5</h2>
					<h2>Glasindex</h2>
					<h2>Gewicht</h2>
				</div>
				{indices.map((item, i) => (
					<div key={i} className={itemStyle}>
						<div>
							<img
								className={css`width: 100%;`}
								src={item.imgSrc}
							/>
						</div>
						<h2>{item.label}</h2>
						<div
							className={css`
								${iconStyle}
								width: ${item.weight * 2}rem;
							`}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default ThicknessSlides;
